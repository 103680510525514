import React from 'react';
import { motion } from 'framer-motion';
import { Star, DollarSign, Gift } from 'lucide-react';
import stakelogo from '../assets/Stake_logo.svg'
import roobetlogo from '../assets/roobet_logo.svg'
import duelbits from '../assets/duelbits.png'



const casinos = [
  {
    name: 'Stake',
    logo: stakelogo,
    bonus: '200% up to $1000',
    rating: 4.8,
    features: ['Crypto Friendly', 'Exclusive Games', 'VIP Program'],
    url: 'https://stake.com/?c=oMXEkKvg'
  },
  {
    name: 'Roobet',
    logo: roobetlogo,
    bonus: '100% up to $500',
    rating: 4.5,
    features: ['Instant Withdrawals', 'Live Casino', '24/7 Support'],
    url: 'https://roobet.com'
  },
  {
    name: 'Duelbits',
    logo: duelbits,
    bonus: '150% up to $750',
    rating: 4.6,
    features: ['Sports Betting', 'Provably Fair', 'Mobile Friendly'],
    url: 'https://duelbits.com'
  }
];

export default function CasinoPartners() {
  return (
    <section className="relative py-8 md:py-12">
    <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12 text-white">Featured Casino Partners</h2>
    
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
      {casinos.map((casino, index) => (
        <motion.div
          key={casino.name}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          viewport={{ once: true }}
          className="bg-gray-800 rounded-xl md:rounded-2xl overflow-hidden"
        >
          <div className="p-6">
            <div className="h-32 flex items-center justify-center mb-6">
              <img
                src={casino.logo}
                alt={`${casino.name} logo`}
                className="max-h-full max-w-full object-contain"
              />
            </div>
            
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg md:text-xl font-semibold text-white">{casino.name}</h3>
              <div className="flex items-center">
                <Star className="w-4 h-4 md:w-5 md:h-5 text-yellow-500 mr-1" />
                <span className="text-white">{casino.rating}</span>
              </div>
            </div>
            
            <div className="flex items-center text-green-400 mb-4">
              <Gift className="w-4 h-4 md:w-5 md:h-5 mr-2" />
              <span className="text-sm md:text-base">{casino.bonus}</span>
            </div>
            
            <ul className="space-y-2 mb-6">
              {casino.features.map(feature => (
                <li key={feature} className="text-gray-400 flex items-center text-sm md:text-base">
                  <DollarSign className="w-4 h-4 mr-2 text-purple-400" />
                  {feature}
                </li>
              ))}
            </ul>
            
            <a
              href={casino.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full bg-purple-600 hover:bg-purple-700 text-white py-2.5 md:py-3 rounded-lg font-medium transition-colors text-center text-sm md:text-base"
            >
              Claim Bonus
            </a>
          </div>
        </motion.div>
      ))}
    </div>
  </section>
  );
}
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronLeft, Clock, BookOpen } from 'lucide-react';
import { guides } from '../data/guides';

export default function GuideArticle() {
  const { articleId } = useParams();
  const guide = guides.find(g => g.id === articleId);

  if (!guide) {
    return (
      <div className="min-h-screen bg-gray-900 py-12 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-2xl text-white">Guide not found</h1>
          <Link to="/guide" className="text-purple-400 hover:text-purple-300">
            Return to guides
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4">
      <article className="max-w-4xl mx-auto">
        <Link
          to="/guide"
          className="inline-flex items-center text-purple-400 hover:text-purple-300 mb-8"
        >
          <ChevronLeft className="w-5 h-5 mr-2" />
          Back to Guides
        </Link>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800 rounded-2xl p-8 md:p-12"
        >
          <header className="mb-8">
            <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
              {guide.title}
            </h1>
            <div className="flex items-center gap-6 text-gray-400">
              <div className="flex items-center">
                <Clock className="w-5 h-5 mr-2" />
                <span>{guide.readTime}</span>
              </div>
              <div className="flex items-center">
                <BookOpen className="w-5 h-5 mr-2" />
                <span>Complete Guide</span>
              </div>
            </div>
          </header>

          <div className="prose prose-invert max-w-none">
            {guide.content.sections.map((section, index) => (
              <motion.section
                key={section.subtitle}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="mb-12"
              >
                <h2 className="text-2xl font-bold text-white mb-4">
                  {section.subtitle}
                </h2>
                <p className="text-gray-300 mb-6 leading-relaxed">
                  {section.text}
                </p>
                <ul className="space-y-4">
                  {section.points.map((point, i) => (
                    <li
                      key={i}
                      className="flex items-start text-gray-300"
                    >
                      <span className="text-purple-400 mr-4">•</span>
                      {point}
                    </li>
                  ))}
                </ul>
              </motion.section>
            ))}
          </div>
        </motion.div>
      </article>
    </div>
  );
}
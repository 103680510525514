import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ChevronRight, Clock } from 'lucide-react';
import { guides } from '../data/guides';
import { Helmet } from 'react-helmet-async';

export default function Guide() {
  return (
    <>
          <Helmet>
        <title>Casino Gambling Guide | Trainwreckstv</title>
        <meta name="description" content="Master casino games with our comprehensive gambling guides. Learn strategies, tips, and best practices for slots, roulette, blackjack, and more." />
        <link rel="canonical" href="https://trainwreckstv.net/guide" />
        <meta property="og:title" content="Casino Gambling Guide | Trainwreckstv" />
        <meta property="og:description" content="Master casino games with our comprehensive gambling guides." />
        <meta property="og:url" content="https://trainwreckstv.net/guide" />
      </Helmet>


    <div className="min-h-screen bg-gray-900 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">Casino Guides</h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Master different casino games with our comprehensive guides
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8">
          {guides.map((guide, index) => (
            <motion.div
              key={guide.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Link
                to={`/guide/${guide.id}`}
                className="block bg-gray-800 rounded-2xl p-8 h-full hover:bg-gray-800/80 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-center">
                    <guide.icon className={`w-8 h-8 ${guide.color} mr-4`} />
                    <h2 className="text-xl font-bold text-white">{guide.title}</h2>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </div>
                
                <p className="text-gray-400 mt-4 mb-6">{guide.description}</p>
                
                <div className="flex items-center text-gray-500">
                  <Clock className="w-4 h-4 mr-2" />
                  <span>{guide.readTime}</span>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
}
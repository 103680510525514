import React from 'react';
import { ArrowRight } from 'lucide-react';
import trains from '../assets/trains.png'
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="relative h-[60vh] md:h-[80vh] overflow-hidden">
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1511882150382-421056c89033?auto=format&fit=crop&q=80"
          alt="Casino atmosphere"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-900/95 to-gray-900/70" />
      </div>
      
      <div className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4 md:px-8 max-w-6xl mx-auto">
        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-8 mb-6 md:mb-8">
          <img
            src={trains}
            alt="Trainwreckstv Profile"
            className="w-24 h-24 md:w-32 md:h-32 rounded-full border-4 border-purple-500 shadow-xl"
          />
        </div>
        <h1 className="text-3xl md:text-6xl font-bold mb-4 md:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
        Trainwreckstv
        </h1>
        <p className="text-lg md:text-xl text-gray-300 max-w-3xl mb-6 md:mb-8 px-4">
          Join the most thrilling casino streaming community. Experience epic wins, exclusive bonuses, and premium entertainment.
        </p>
        <Link 
          to="/promotions"
          className="bg-purple-600 hover:bg-purple-700 text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-medium flex items-center gap-2 transform hover:scale-105 transition-all"
        >
          Explore Casinos
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>
    </div>
  );
}
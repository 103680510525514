import React from 'react';
import { motion } from 'framer-motion';
import { Gift, Info, Gamepad2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const links = [
  {
    title: 'Casino Promotions',
    description: 'Exclusive bonuses and free spins',
    icon: Gift,
    to: '/promotions',
    gradient: 'from-purple-500 to-pink-500'
  },
  {
    title: 'About Trainwreckstv',
    description: 'Learn about the legendary streamer',
    icon: Info,
    to: '/about',
    gradient: 'from-blue-500 to-cyan-500'
  },
  {
    title: 'Top Games',
    description: 'Most popular casino games',
    icon: Gamepad2,
    to: '/guide',
    gradient: 'from-amber-500 to-red-500'
  }
];

export default function QuickLinks() {
  return (
    <section className="py-8 md:py-12 px-4 md:px-0">
      <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12 text-white">Quick Access</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
        {links.map((link, index) => (
          <motion.div
            key={link.title}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            viewport={{ once: true }}
          >
            <Link
              to={link.to}
              className="block group relative overflow-hidden rounded-xl md:rounded-2xl bg-gray-800 p-6 md:p-8 h-full transition-transform hover:-translate-y-2"
            >
              <div className={`absolute inset-0 bg-gradient-to-r ${link.gradient} opacity-0 group-hover:opacity-10 transition-opacity`} />
              
              <link.icon className="w-10 h-10 md:w-12 md:h-12 mb-4 text-white" />
              
              <h3 className="text-lg md:text-xl font-semibold text-white mb-2">{link.title}</h3>
              <p className="text-sm md:text-base text-gray-400">{link.description}</p>
            </Link>
          </motion.div>
        ))}
      </div>
    </section>
  );
}
import React from 'react';
import { motion } from 'framer-motion';
import { Twitch, Twitter, Instagram } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const socialLinks = [
  { icon: Twitch, label: 'Twitch', href: 'https://twitch.tv/trainwreckstv', color: 'hover:text-purple-500' },
  { icon: Twitter, label: 'Twitter', href: 'https://twitter.com/trainwreckstv', color: 'hover:text-blue-400' },
  { icon: Instagram, label: 'Instagram', href: 'https://instagram.com/trainwreckstv', color: 'hover:text-pink-500' }
];

export default function About() {
  return (
    <>
          <Helmet>
        <title>About Trainwreckstv | Casino Streaming Legend</title>
        <meta name="description" content="Learn about Trainwreckstv, one of the most influential figures in casino streaming. Discover his journey, achievements, and impact on the gambling community." />
        <link rel="canonical" href="https://trainwreckstv.net/about" />
        <meta property="og:title" content="About Trainwreckstv | Casino Streaming Legend" />
        <meta property="og:description" content="Learn about Trainwreckstv, one of the most influential figures in casino streaming." />
        <meta property="og:url" content="https://trainwreckstv.net/about" />
      </Helmet>


    <div className="min-h-screen bg-gray-900 py-12 px-4">
      <div className="max-w-4xl mx-auto space-y-16">
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">About Trainwreckstv</h1>
          <div className="flex justify-center gap-6 mb-8">
            {socialLinks.map(({ icon: Icon, label, href, color }) => (
              <a
                key={label}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className={`text-gray-400 ${color} transition-colors`}
              >
                <Icon className="w-6 h-6" />
              </a>
            ))}
          </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-gray-800 rounded-2xl p-8 md:p-12"
        >
<h2 className="text-2xl font-bold text-white mb-6">The Life and Rise of Trainwreckstv</h2>
<div className="space-y-4 text-gray-300">
  <p>
    Trainwreckstv, whose real name is Tyler Niknam, is a popular American streamer best known for his high-energy casino
    streams, controversial rants, and engaging personality. He rose to fame on Twitch and later became one of the leading
    figures in the casino streaming niche, captivating millions with his unique blend of content that includes high-stakes
    gambling, variety gaming, and podcasts.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">Early Life and Streaming Beginnings</h2>
<div className="space-y-4 text-gray-300">
  <p>
    Born on December 20, 1990, Trainwreckstv is originally from Scottsdale, Arizona. He studied Analytical Philosophy at
    Arizona State University, but it was in the gaming world that he would find his true calling. Like many streamers,
    Trainwreckstv began his journey on Twitch playing popular video games. Over time, his charisma, wit, and sometimes
    polarizing opinions drew an increasing number of followers.
  </p>
  <p>
    Trainwreckstv wasn’t afraid to push boundaries, often hosting discussions on topics that other streamers avoided, which
    further solidified his presence in the gaming and streaming scene. His unapologetic rants and raw content attracted a
    loyal fan base who admired his straightforward attitude.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">How Did Trainwreckstv Get Famous?</h2>
<div className="space-y-4 text-gray-300">
  <p>
    Trainwreckstv’s fame grew exponentially when he began streaming high-stakes casino content. Known for his love of slot
    machines and bold bets, Tyler captivated a growing audience who tuned in to witness thrilling moments, massive wins, and
    sometimes devastating losses. His gambling streams often featured games like Plinko, slots, and other online casino
    favorites, turning his channel into one of the most-watched in the iGaming community.
  </p>
  <p>
    Beyond gambling, Trainwreckstv is also known for his popular podcast, Scuffed Podcast, where he regularly invites other
    prominent figures from the streaming and gaming worlds to discuss current events, drama, and industry trends. His
    straightforward nature and bold opinions have made the podcast a hit among fans.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">Wealth and Success: How Does Trainwreckstv Have So Much Money?</h2>
<div className="space-y-4 text-gray-300">
  <p>
    Trainwreckstv's wealth primarily comes from his streaming career and gambling partnerships. With millions of followers
    across Twitch (before his move) and Kick, his income comes from a combination of donations, subscriptions, and lucrative
    brand deals. His high-stakes gambling also contributes to his wealth, as he often participates in sponsored streams or
    plays on Stake, one of the leading crypto casinos.
  </p>
  <p>
    While the exact figures of his net worth are hard to pin down, it's speculated that Trainwreckstv is worth several
    millions of dollars. His massive wins from slots and other games, along with sponsorships from top gambling platforms,
    have made him one of the richest figures in the streaming world. Despite some losses on stream, Trainwreckstv has a
    substantial income flow that more than offsets the risk.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">Does Trainwreckstv Own Kick?</h2>
<div className="space-y-4 text-gray-300">
  <p>
    While Trainwreckstv doesn’t own Kick, he is heavily involved in the platform. Kick.com, a newer streaming platform, has
    quickly become Trainwreckstv’s main streaming home after his departure from Twitch. Trainwreckstv is one of the
    platform’s biggest promoters and is reportedly financially invested in its growth. His move to Kick represents a new era
    for his streaming career, where he enjoys more creative freedom and has become one of the most popular creators on the
    site.
  </p>
  <p>
    Kick has positioned itself as a direct competitor to Twitch, offering better revenue splits for creators and attracting
    many casino streamers. Trainwreckstv’s involvement has brought massive attention to the platform, with many of his fans
    migrating to watch his live casino streams and other gaming content there.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">Trainwreckstv’s Personal Life: Where Does He Live?</h2>
<div className="space-y-4 text-gray-300">
  <p>
    As of now, Trainwreckstv splits his time between different locations, but he’s rumored to reside in Canada. Due to his
    gambling content, which often faces stricter regulations in the United States, Canada has become a popular location for
    many casino streamers.
  </p>
  <p>
    Trainwreckstv is relatively private about his personal life outside of streaming. However, fans get glimpses of his life
    through his social media accounts, where he occasionally shares his thoughts on current events, his travels, and his
    gaming setup.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">What Is Trainwreckstv’s Net Worth?</h2>
<div className="space-y-4 text-gray-300">
  <p>
    As mentioned, Trainwreckstv’s net worth is estimated to be in the multi-millions, thanks to his success in the streaming
    and gambling industries. With his casino sponsorships, donations, and paid partnerships, Trainwreckstv is one of the
    highest-earning content creators in the online casino space.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">Where Is Trainwreckstv Streaming Now?</h2>
<div className="space-y-4 text-gray-300">
  <p>
    Trainwreckstv now primarily streams on Kick.com. After leaving Twitch, where he initially built his following, Kick
    offered him a platform with better revenue models and more flexibility in the content he could create. This has allowed
    Trainwreckstv to focus more on his casino streams and continue providing the high-stakes entertainment that his fans
    love.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">How Old Is Trainwreckstv?</h2>
<div className="space-y-4 text-gray-300">
  <p>
    Trainwreckstv is currently 33 years old (born in 1990). Despite his relatively young age, he has amassed significant
    wealth and influence in the streaming world, making him a leading figure in the casino streaming space.
  </p>
</div>
<br/>
<h2 className="text-2xl font-bold text-white mb-6">The Future of Trainwreckstv</h2>
<div className="space-y-4 text-gray-300">
  <p>
    Trainwreckstv’s career shows no signs of slowing down. With a dedicated fan base, a prominent place on Kick, and an
    ever-growing presence in the online gambling world, he continues to push the envelope of what it means to be a
    successful casino streamer. Whether it’s through his famous Bonus Hunts, engaging podcasts, or thrilling live streams,
    Trainwreckstv remains one of the most influential figures in the world of live streaming.
  </p>
</div>

        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="grid md:grid-cols-2 gap-8"
        >
          <div className="bg-gray-800 rounded-2xl p-8">
            <h2 className="text-2xl font-bold text-white mb-6">Content Creation</h2>
            <ul className="space-y-4 text-gray-300">
              <li>• High-stakes slot sessions</li>
              <li>• Live casino game streams</li>
              <li>• Community interaction</li>
              <li>• Transparent gambling approach</li>
            </ul>
          </div>
          <div className="bg-gray-800 rounded-2xl p-8">
            <h2 className="text-2xl font-bold text-white mb-6">Achievements</h2>
            <ul className="space-y-4 text-gray-300">
              <li>• Multiple million-dollar wins</li>
              <li>• Industry-leading partnerships</li>
              <li>• Massive community growth</li>
              <li>• Pioneering content format</li>
            </ul>
          </div>
        </motion.section>
      </div>
    </div>
    </>
  );
}
import React from 'react';
import { motion } from 'framer-motion';
import { TwitchEmbed } from 'react-twitch-embed';
import { Helmet } from 'react-helmet-async';

export default function Streams() {
  return (
    <>
          <Helmet>
        <title>Live Casino Streams | Trainwreckstv</title>
        <meta name="description" content="Watch Trainwreckstv's live casino streams, catch up on recent highlights, and never miss a big win. Join the most exciting gambling streams on Twitch." />
        <link rel="canonical" href="https://trainwreckstv.net/streams" />
        <meta property="og:title" content="Live Casino Streams | Trainwreckstv" />
        <meta property="og:description" content="Watch Trainwreckstv's live casino streams and catch up on recent highlights." />
        <meta property="og:url" content="https://trainwreckstv.net/streams" />
      </Helmet>


    <div className="min-h-screen bg-gray-900 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">Live Streams</h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Watch Trainwreckstv live in action and catch up on recent highlights
          </p>
        </motion.div>

        <div className="grid lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="bg-gray-800 rounded-2xl p-4 aspect-video">
              <TwitchEmbed
                channel="trainwreckstv"
                width="100%"
                height="100%"
                withChat={false}
              />
            </div>
          </div>

          <div className="space-y-6">
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="bg-gray-800 rounded-2xl p-6"
            >
              <h2 className="text-xl font-bold text-white mb-4">Stream Schedule</h2>
              <div className="space-y-3 text-gray-300">
                <p>• Monday: 8 PM - 4 AM EST</p>
                <p>• Wednesday: 9 PM - 5 AM EST</p>
                <p>• Friday: 10 PM - 6 AM EST</p>
                <p>• Saturday: Special Events</p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="bg-gray-800 rounded-2xl p-6"
            >
              <h2 className="text-xl font-bold text-white mb-4">Recent Highlights</h2>
              <div className="space-y-4">
                {/* Highlight clips will be added here */}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
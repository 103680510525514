import React from 'react';
import { motion } from 'framer-motion';
import { Gift, DollarSign, CreditCard, Bitcoin, Star, ExternalLink } from 'lucide-react';
import stakelogo from '../assets/Stake_logo.svg'
import roobetlogo from '../assets/roobet_logo.svg'
import duelbits from '../assets/duelbits.png'
import rollbit from '../assets/rollbit.svg'
import bcgame from "../assets/bcgame.jpg"
import gamdom from "../assets/gamdom.webp"
import { Helmet } from 'react-helmet-async';

const filters = [
  { label: 'All Bonuses', icon: Gift, type: 'all' },
  { label: 'Free Spins', icon: DollarSign, type: 'spins' },
  { label: 'Deposit Bonus', icon: CreditCard, type: 'deposit' },
  { label: 'Crypto Bonus', icon: Bitcoin, type: 'crypto' }
];

const casinos = [
  {
    name: 'Stake',
    logo: stakelogo,
    rating: 4.8,
    types: ['deposit', 'crypto'],
    mainBonus: '200% up to $1000',
    extraBonus: '50 Free Spins',
    features: ['Instant Withdrawals', 'VIP Program', '24/7 Support'],
    cryptoSupported: true,
    url: "https://stake.com/?c=oMXEkKvg"
  },
  {
    name: 'Roobet',
    logo: roobetlogo,
    rating: 4.5,
    types: ['spins', 'deposit'],
    mainBonus: '100% up to $500',
    extraBonus: '100 Free Spins',
    features: ['Exclusive Games', 'Live Casino', 'Mobile Friendly'],
    cryptoSupported: false,
    url: "https://roobet.com"
  },
  {
    name: 'Duelbits',
    logo: duelbits,
    rating: 4.6,
    types: ['crypto', 'deposit'],
    mainBonus: '150% up to $750',
    extraBonus: 'Daily Cashback',
    features: ['Sports Betting', 'Provably Fair', 'Quick Registration'],
    cryptoSupported: true,
    url: "https://duelbits.com"
  },
  {
    name: 'Rollbit',
    logo: rollbit,
    rating: 4.7,
    types: ['spins', 'crypto'],
    mainBonus: '175% up to $1000',
    extraBonus: '200 Free Spins',
    features: ['NFT Integration', 'Trading Platform', 'Exclusive Games'],
    cryptoSupported: true,
    url: "https://rollbit.com"
  },
  {
    name: 'BC.Game',
    logo: bcgame,
    rating: 4.4,
    types: ['deposit', 'crypto'],
    mainBonus: '180% up to $1200',
    extraBonus: 'Daily Free Spin',
    features: ['Social Platform', 'Original Games', 'Multiple Currencies'],
    cryptoSupported: true,
    url: "https://bc.game"
  },
  {
    name: 'Gamdom',
    logo: gamdom,
    rating: 4.3,
    types: ['spins', 'deposit'],
    mainBonus: '120% up to $600',
    extraBonus: '75 Free Spins',
    features: ['Esports Betting', 'Rakeback System', 'Community Chat'],
    cryptoSupported: false,
    url: "https://gamdom.com"
  }
];

export default function Promotions() {
  const [activeFilter, setActiveFilter] = React.useState('all');
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredCasinos = casinos.filter(casino => {
    const matchesFilter = activeFilter === 'all' || casino.types.includes(activeFilter);
    const matchesSearch = casino.name.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  return (
    <>
      <Helmet>
    <title>Exclusive Casino Promotions & Bonuses | Trainwreckstv</title>
    <meta name="description" content="Discover exclusive casino bonuses through Trainwreckstv. Get the best welcome offers, free spins, and crypto bonuses from trusted gaming sites." />
    <link rel="canonical" href="https://trainwreckstv.net/promotions" />
    <meta property="og:title" content="Exclusive Casino Promotions & Bonuses | Trainwreckstv" />
    <meta property="og:description" content="Discover exclusive casino bonuses through Trainwreckstv. Get the best welcome offers and bonuses from trusted gaming sites." />
    <meta property="og:url" content="https://trainwreckstv.net/promotions" />
    <meta property="og:type" content="website" />
  </Helmet>
    

    <div className="min-h-screen bg-gray-900 py-12 px-4">
    <div className="max-w-7xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">Exclusive Casino Promotions</h1>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Discover the best casino bonuses and promotions, handpicked by Trainwreckstv
        </p>
      </motion.div>

      <div className="mb-8">
        <input
          type="text"
          placeholder="Search casinos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full max-w-md mx-auto block bg-gray-800 border-gray-700 rounded-lg px-4 py-3 text-white focus:ring-2 focus:ring-purple-500 focus:border-transparent"
        />
      </div>

      <div className="flex flex-wrap gap-4 justify-center mb-12">
        {filters.map(({ label, icon: Icon, type }) => (
          <button
            key={label}
            onClick={() => setActiveFilter(type)}
            className={`flex items-center px-6 py-3 rounded-full transition-colors ${
              activeFilter === type
                ? 'bg-purple-600 text-white'
                : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
            }`}
          >
            <Icon className="w-5 h-5 mr-2" />
            {label}
          </button>
        ))}
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredCasinos.map((casino, index) => (
          <motion.div
            key={casino.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-gray-800 rounded-2xl overflow-hidden"
          >
            <div className="relative p-6 pb-4">
              <div className="absolute top-4 right-4 flex items-center">
                <Star className="w-5 h-5 text-yellow-500 mr-1" />
                <span className="text-white font-medium">{casino.rating}</span>
              </div>
              <div className="h-32 flex items-center justify-center mb-4">
                <img
                  src={casino.logo}
                  alt={`${casino.name} logo`}
                  className="max-h-full max-w-full object-contain"
                />
              </div>
              <h3 className="text-xl font-bold text-white mb-2">{casino.name}</h3>
              <div className="space-y-2 mb-4">
                <p className="text-purple-400 font-medium">{casino.mainBonus}</p>
                <p className="text-green-400 text-sm">{casino.extraBonus}</p>
              </div>
              <div className="space-y-2 mb-6">
                {casino.features.map(feature => (
                  <div key={feature} className="flex items-center text-gray-400">
                    <DollarSign className="w-4 h-4 mr-2 text-purple-400" />
                    <span className="text-sm">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="p-4 border-t border-gray-700">
              <a
                href={casino.url}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full bg-purple-600 hover:bg-purple-700 text-white py-3 rounded-lg font-medium transition-colors flex items-center justify-center"
              >
                <ExternalLink className="w-5 h-5 mr-2" />
                Claim Bonus
              </a>
            </div>
          </motion.div>
        ))}
      </div>

      {filteredCasinos.length === 0 && (
        <div className="text-center text-gray-400 py-12">
          No casinos found matching your criteria
        </div>
      )}
    </div>
  </div>
  </>
  );
}
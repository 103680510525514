import React from 'react';
import { ExternalLink, Coins, Menu } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useSidebar } from '../context/SidebarContext';

export default function Navbar() {
  const { setIsOpen } = useSidebar();

  return (
    <nav className="fixed lg:static top-0 left-0 right-0 bg-gray-800 border-b border-gray-700 h-16 flex items-center px-4 md:px-6 justify-between z-30">
      <div className="flex items-center">
        <button
          onClick={() => setIsOpen(true)}
          className="lg:hidden p-2 -ml-2"
        >
          <Menu className="w-6 h-6 text-gray-400" />
        </button>
        

      </div>

      <a
        href="https://stake.com/?c=oMXEkKvg"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 transform hover:scale-105 flex items-center gap-2"
      >
        <Coins className="w-4 h-4" />
        Join Stake
        <ExternalLink className="w-3 h-3" />
      </a>
    </nav>
  );
}
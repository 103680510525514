import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, TrendingUp, Users, Trophy } from 'lucide-react';
import Hero from '../components/Hero';
import QuickLinks from '../components/QuickLinks';
import CasinoPartners from '../components/CasinoPartners';
import StreamerStats from '../components/StreamerStats';
import { Helmet } from 'react-helmet-async';

export default function Home() {
  return (
    <>
          <Helmet>
        <title>Trainwreckstv - Premier Casino Streaming Community</title>
        <meta name="description" content="Join Trainwreckstv's thrilling casino streaming community. Experience epic wins, exclusive bonuses, and premium entertainment." />
        <link rel="canonical" href="https://trainwreckstv.net" />
        <meta property="og:title" content="Trainwreckstv - Premier Casino Streaming Community" />
        <meta property="og:description" content="Join Trainwreckstv's thrilling casino streaming community. Experience epic wins, exclusive bonuses, and premium entertainment." />
        <meta property="og:url" content="https://trainwreckstv.net" />
        <meta property="og:type" content="website" />
      </Helmet>


    <div className="min-h-screen bg-gray-900">
      <Hero />
      
      <div className="max-w-7xl mx-auto px-4">
        <div className="relative -mt-20 z-10">
          <QuickLinks />
        </div>
        
        <div className="space-y-24 py-12">
          <section className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-3xl blur-3xl" />
            <CasinoPartners />
          </section>

          <StreamerStats />
        </div>
      </div>
    </div>
    </>
  );
}
import React from 'react';
import { motion } from 'framer-motion';
import { Users, Clock, TrendingUp, Trophy } from 'lucide-react';

const stats = [
  {
    label: 'Total Followers',
    value: '2.5M+',
    icon: Users,
    gradient: 'from-purple-500 to-pink-500'
  },
  {
    label: 'Hours Streamed',
    value: '15,000+',
    icon: Clock,
    gradient: 'from-blue-500 to-cyan-500'
  },
  {
    label: 'Biggest Win',
    value: '$12M',
    icon: TrendingUp,
    gradient: 'from-green-500 to-emerald-500'
  },
  {
    label: 'Tournament Wins',
    value: '50+',
    icon: Trophy,
    gradient: 'from-amber-500 to-red-500'
  }
];

export default function StreamerStats() {
  return (
    <section className="py-12">
      <h2 className="text-3xl font-bold text-center mb-12 text-white">Streamer Statistics</h2>
      
      <div className="grid md:grid-cols-4 gap-8">
        {stats.map((stat, index) => (
          <motion.div
            key={stat.label}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            viewport={{ once: true }}
            className="relative group"
          >
            <div className={`absolute inset-0 bg-gradient-to-r ${stat.gradient} opacity-5 group-hover:opacity-10 rounded-2xl transition-opacity`} />
            
            <div className="relative bg-gray-800 rounded-2xl p-6 text-center">
              <div className="inline-flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gray-700">
                <stat.icon className="w-6 h-6 text-white" />
              </div>
              
              <h3 className="text-3xl font-bold text-white mb-2">{stat.value}</h3>
              <p className="text-gray-400">{stat.label}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
}
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Twitch, 
  Twitter, 
  Facebook, 
  Instagram, 
  MessageSquare,
  Gamepad2,
  Gift,
  PlayCircle,
  FileText,
  HelpCircle,
  Shield
} from 'lucide-react';
import { ScrollToTop } from './ScrollToTop';

const quickLinks = [
  { label: 'Bonuses', icon: Gift, to: '/promotions' },
  { label: 'Stream', icon: PlayCircle, to: '/streams' },
];

const supportLinks = [
  { label: 'Support', icon: HelpCircle, to: '/contact' },
  { label: 'Terms and Conditions', icon: FileText, to: '/terms' },
  { label: 'Cookie Policy', icon: Shield, to: '/cookies' },
  { label: 'Privacy Policy', icon: Shield, to: '/privacy' }
];

const socialLinks = [
  { icon: Twitch, href: 'https://twitch.tv/trainwreckstv', color: 'hover:text-purple-500' },
  { icon: Twitter, href: 'https://twitter.com/trainwreckstv', color: 'hover:text-blue-400' },
  { icon: Instagram, href: 'https://www.instagram.com/tylerniknam/', color: 'hover:text-pink-500' },
];

export default function Footer() {
  return (
    <footer className="bg-gray-900 border-t border-gray-800">
             <ScrollToTop />
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div>
            <h3 className="text-white font-semibold mb-4">QUICK LINKS</h3>
            <ul className="space-y-2">
              {quickLinks.map(({ label, icon: Icon, to }) => (
                <li key={label}>
                  <Link to={to} className="text-gray-400 hover:text-white flex items-center gap-2">
                    <Icon className="w-4 h-4" />
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-white font-semibold mb-4">SUPPORT & TERMS</h3>
            <ul className="space-y-2">
              {supportLinks.map(({ label, icon: Icon, to }) => (
                <li key={label}>
                  <Link to={to} className="text-gray-400 hover:text-white flex items-center gap-2">
                    <Icon className="w-4 h-4" />
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-white font-semibold mb-4">SOCIAL MEDIA</h3>
            <div className="flex flex-wrap gap-4">
              {socialLinks.map(({ icon: Icon, href, color }) => (
                <a
                  key={href}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-gray-400 ${color} transition-colors`}
                >
                  <Icon className="w-6 h-6" />
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex items-center gap-6">
            <img src="https://www.begambleaware.org/sites/default/files/2021-05/GamCare_Logo_White.png" 
                 alt="GamCare" 
                 className="h-8" />
            <img src="https://www.begambleaware.org/sites/default/files/2021-05/BGA_Logo_White.png" 
                 alt="BeGambleAware" 
                 className="h-8" />
            <span className="text-gray-600">18+</span>
          </div>
          
          <p className="text-gray-500 text-sm">
            © {new Date().getFullYear()} Trainwreckstv.net - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
}
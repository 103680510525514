import { Dices, Gamepad, Target, Coins } from 'lucide-react';

export const guides = [
  {
    id: 'how-to-bet-on-roulette',
    title: 'Complete Guide: How to Bet on Roulette',
    description: 'Master the art of roulette betting with our comprehensive guide. Learn about different betting strategies, odds calculation, and expert tips.',
    icon: Target,
    color: 'text-red-500',
    readTime: '15 min read',
    content: {
      sections: [
        {
          subtitle: 'Understanding Roulette Basics',
          text: 'Before diving into advanced strategies, it\'s crucial to understand the fundamentals of roulette. This classic casino game offers various betting options and potential payouts.',
          points: [
            'European vs American roulette differences and house edge',
            'Inside bets: Straight, Split, Street, Corner, and Six Line',
            'Outside bets: Red/Black, Odd/Even, Dozens, and Columns',
            'Understanding the roulette table layout and betting areas'
          ]
        },
        {
          subtitle: 'Advanced Betting Strategies',
          text: 'While no strategy guarantees wins, these proven systems can help manage your bankroll and potentially increase your chances of success.',
          points: [
            'Martingale System: Progressive betting strategy explained',
            'D\'Alembert System: A safer alternative to Martingale',
            'Fibonacci Strategy: Using the famous sequence for betting',
            'James Bond Strategy: Combining different types of bets'
          ]
        },
        {
          subtitle: 'Money Management Tips',
          text: 'Proper bankroll management is crucial for long-term success in roulette. Learn how to protect your funds while maximizing potential returns.',
          points: [
            'Setting win goals and stop-loss limits',
            'Calculating optimal bet sizes based on your bankroll',
            'When to increase or decrease your bets',
            'Managing winning and losing streaks effectively'
          ]
        }
      ]
    }
  },
  {
    id: 'slot-machines-strategy',
    title: 'Slot Machines: Complete Strategy Guide',
    description: 'Discover everything about modern slot machines, from RTP and volatility to bonus features and progressive jackpots.',
    icon: Dices,
    color: 'text-purple-500',
    readTime: '12 min read',
    content: {
      sections: [
        {
          subtitle: 'Understanding Modern Slots',
          text: 'Modern slot machines are complex gaming systems with various features and mechanics. Learn how they work to make informed decisions.',
          points: [
            'RTP (Return to Player) explained in detail',
            'Volatility levels and their impact on gameplay',
            'Random Number Generators (RNG) and fair play',
            'Progressive vs. Fixed jackpot systems'
          ]
        },
        {
          subtitle: 'Bonus Features and Mechanics',
          text: 'Modern slots come with numerous bonus features that can significantly impact your winning potential.',
          points: [
            'Free spins and their variations',
            'Multiplier mechanics and combinations',
            'Wild symbols and their special functions',
            'Scatter symbols and bonus triggers'
          ]
        },
        {
          subtitle: 'Optimal Playing Strategies',
          text: 'While slots are games of chance, certain strategies can help you maximize your entertainment and potential returns.',
          points: [
            'Choosing the right slot based on your budget',
            'Understanding bet sizes and paylines',
            'Taking advantage of casino bonuses',
            'When to stop playing and cash out'
          ]
        }
      ]
    }
  }
  // Additional guides can be added here
];